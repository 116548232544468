import { DirectoryUser } from 'src/app/data/data.models';
import { CheckResult, SingleUserCheck } from '../integrity.models';

export class ValidValuesCheck extends SingleUserCheck {
    constructor(field: keyof DirectoryUser, public validValues: string) {
        super(field);
    }

    runSingle(user: DirectoryUser): CheckResult | null {
        const value = user[this.field] as string;
        const allowedValues = this.validValues.split(',').map((v) => v.trim()).filter(x => x !== '');
        if (!allowedValues.includes(value)) {
            return {
                checkName: 'ValidValuesCheck',
                userId: user.id,
                message: `Field ${this.field} contains invalid value: ${value}`,
                checkIndex: -1,
                isFixed: false,
                isModified: false
            };
        }
        return null;
    }

    getDisplayName(): string {
        return `Valied values in ${this.field}`;
    }

    getDescription(): string {
        return `Check if the field ${this.field} contains an invalid value.`;
    }
}
