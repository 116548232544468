import { DirectoryUser } from 'src/app/data/data.models';
import { ReportConfig } from './report-config';
import { AllUsersCheck, CheckResult, DirectoryUserModification, SingleUserCheck } from '../integrity.models';
import { ReportResult } from './report-result';

export class IntegrityReport {
    constructor(public name: string, private config: ReportConfig) {}

    run(users: DirectoryUser[], modifiedUsers: DirectoryUser[]): ReportResult {
        const results: CheckResult[] = [];
        const modifiedCheckResults: CheckResult[] = [];
        const checks = this.config.getChecks();

        // Separate SingleUserCheck and AllUsersCheck
        const singleUserChecks = checks.filter((check) => check instanceof SingleUserCheck) as SingleUserCheck[];
        const allUsersChecks = checks.filter((check) => check instanceof AllUsersCheck) as AllUsersCheck[];

        // Traverse users only once for all SingleUserChecks
        users.forEach((user) => {
            const modifiedUser = modifiedUsers.find((u) => u.id === user.id);
            checks.forEach((check, index) => {
                if (check instanceof SingleUserCheck) {
                    const result = check.runSingle(user);
                    const modifiedResult = modifiedUser ? check.runSingle(modifiedUser) : null;
                    if (result) {
                        result.checkIndex = index;
                        if (!modifiedResult) {
                            result.isFixed = true;
                            result.isModified = true;
                        }
                        results.push(result);
                    }
                    if (modifiedResult && !result) {
                        modifiedResult.checkIndex = index;
                        modifiedResult.isFixed = false;
                        modifiedResult.isModified = true;
                    }
                    if (modifiedResult) {
                        modifiedResult.checkIndex = index;
                        modifiedCheckResults.push(modifiedResult);
                    }
                }
            });
        });

        // Run all AllUsersChecks (which inherently traverse all users)
        checks.forEach((check, index) => {
            if (check instanceof AllUsersCheck) {
                const checkResults = check.run(users).map((result) => {
                    result.checkIndex = index;
                    return result;
                });
                results.push(...checkResults);
            }
        });
        //now run the checks for the modified users
        //if the result from the modified user is null, then the issue has been fixed
        checks.forEach((check, index) => {
            if (check instanceof AllUsersCheck) {
                const checkResults = check.run(modifiedUsers).map((result) => {
                    result.checkIndex = index;
                    return result;
                });
                modifiedCheckResults.push(...checkResults);
                //go through the results and check if the issue has been fixed
                //if the check result is not in the modified results, but is in the original results, then the issue has been fixed, mark issue in original results as fixed
                results.forEach((result) => {
                    const modifiedResult = modifiedCheckResults.find(
                        (r) => r.userId === result.userId && r.checkIndex === result.checkIndex
                    );
                    if (!modifiedResult) {
                        result.isFixed = true;
                        result.isModified = true;
                    }
                });
                modifiedCheckResults.forEach((result) => {
                    const originalResult = results.find(
                        (r) => r.userId === result.userId && r.checkIndex === result.checkIndex
                    );
                    if (!originalResult) {
                        result.isFixed = false;
                        result.isModified = true;
                        results.push(result);
                    }
                });
            }
        });

        // Group results by check name
        const resultsByCheck = results.reduce((acc, result) => {
            if (!acc[result.checkIndex]) {
                acc[result.checkIndex] = [];
            }
            acc[result.checkIndex].push(result);
            return acc;
        }, {} as { [checkIndex: number]: CheckResult[] });

        // Group results by user ID
        const resultsByUser = results.reduce((acc, result) => {
            if (!acc[result.userId]) {
                acc[result.userId] = [];
            }
            acc[result.userId].push(result);
            return acc;
        }, {} as { [userId: string]: CheckResult[] });

        return {
            id: 1,
            reportName: this.name,
            timestamp: new Date().toISOString(),
            results,
            resultsByCheck,
            resultsByUser,
            reportConfig: this.config.toJSON(),
            checkInfo: checks.map((check, index) => ({
                name: check.getDisplayName(),
                description: check.getDescription(),
                fields: check.getField() ? [check.getField() as string] : []
            }))
        };
    }
}
