import { DirectoryUser } from 'src/app/data/data.models';
import { CheckResult, SingleUserCheck } from '../integrity.models';

export class NoManagerCheck extends SingleUserCheck {
    constructor() {
        super('managerId');
    }
    runSingle(user: DirectoryUser): CheckResult | null {
        if (!user[this.field]) {
            return {
                checkName: 'NoManagerCheck',
                userId: user.id,
                message: `No manager set`,
                checkIndex: -1, // This will be set correctly in the Report class,
                isFixed: false,
                isModified: false
            };
        }
        return null;
    }

    getField(): string {
        return this.field;
    }

    getDisplayName(): string {
        return `No manager set`;
    }

    getDescription(): string {
        return `Check if users have empty values in ${this.field}.`;
    }
}
