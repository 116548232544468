import { DirectoryUser } from 'src/app/data/data.models';
import { CheckResult, SingleUserCheck } from '../integrity.models';

export class ContainsDuplicateValuesCheck extends SingleUserCheck {
    constructor(field: keyof DirectoryUser) {
        super(field);
    }

    runSingle(user: DirectoryUser): CheckResult | null {
        const value = user[this.field] as string;
        const values = value.split(',').map((v) => v.trim());
        const duplicates = values.filter((v, i, a) => a.indexOf(v) !== i);
        if (duplicates.length > 0) {
            return {
                checkName: 'ContainsDuplicateValuesCheck',
                userId: user.id,
                message: `Field ${this.field} contains duplicate values: ${duplicates.join(', ')}`,
                checkIndex: -1,
                isFixed: false,
                isModified: false
            };
        }
        return null;
    }

    getDisplayName(): string {
        return `Duplicate values in ${this.field}`;
    }

    getDescription(): string {
        return `Check if the field ${this.field} contains duplicate values.`;
    }
}
